import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import animate from "animate.css";
import ElementPlus from "element-plus";
import * as ElementPlusIcons from "@element-plus/icons-vue";
import "element-plus/dist/index.css";
// import 'amfe-flexible'
// import "swiper/swiper.min.css"
const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIcons)) {
    app.component(key, component);
  }
app.use(animate)
.use(router)
.use(ElementPlus)
.mount('#app')
