<template>
  <transition name="fade-transform" mode="out-in">
    <component :is="layout">
      <router-view v-if="isRouterAlive" />
    </component>
  </transition>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      defaultLayout: "main-layout",
      isRouterAlive: true,
    };
  },
  computed: {
    layout() {
      const layout = this.$route.meta.layout;
      return layout ? `${layout}-layout` : this.defaultLayout;
    },
  },
  methods: {
    reload() {
      // 通过声明reload方法，控制router-view的显示或隐藏，从而控制页面的再次加载
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },

  },
};
</script>

<style>
@import url(./assets/style/basic.less);
</style>
