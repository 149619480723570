import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/views/home/index.vue";

import NProgress from "nprogress"; // Progress 进度条
import "nprogress/nprogress.css"; // Progress 进度条样式
const baseRouter = [
  {
    path: "/",
    name: "home",
    component: HomePage,
    meta: {
      isMenu: true,
      layout: "main",
      title: "首页",
      icon: "home",
    },
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/",
    meta: {
      layout: "main",
      title: "404",
    },
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes: [...baseRouter],
});

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  // 允许进入登录页面  1005 需要完善信息 不能清除登录信息
  next();
  NProgress.done();
  return;
});

router.afterEach(() => {
  NProgress.done(); // 结束Progress
});
export default router;
