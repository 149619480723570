<template>
    <div class="cooperative">
      <div class="title">{{ title }}</div>
      <div class="desc">{{ desc }}</div>
      <div class="list">
              <div
                class="item scroll-item animate__flipInY"
                data-wow-duration="2s"
                :style="{ animationDelay: `${Math.random()}s` }"
                v-for="(item, index) in data"
                :key="index"
              >
                <div class="icon" :style="{width: item.width}">
                  <img :src="item.icon" alt="" />
                </div>
              </div>
      </div>
    </div>
  </template>
    
    <script>
  import icon1 from "@/assets/image/logo/zhongguoyinhang.png";
  import icon2 from "@/assets/image/logo/zhongguojiansheyinhang.png";
  import icon3 from "@/assets/image/logo/zhongxinyinhang.png";
  import icon4 from "@/assets/image/logo/huaxiayinhang.png";
  import icon5 from "@/assets/image/logo/guanfengyinhang.png";
  import icon6 from "@/assets/image/logo/zhifubaozhifu.png";
  import icon7 from "@/assets/image/logo/zhongjinzhifu.png";
  import icon8 from "@/assets/image/logo/yibaozhifu.png";
  export default {
    name: "cooperative-component",
    data() {
      return {
        title: "合作伙伴",
        desc: "与各大金融平台/机构深度合作，资金安全更有保障",
        data: [
          { icon: icon1, width: "220px" },
          { icon: icon2, width: "260px" },
          { icon: icon3, width: "100%" },
          { icon: icon4, width: "220px" },
          { icon: icon5, width: "200px" },
          { icon: icon6, width: "140px" },
          { icon: icon7, width: "200px" },
          { icon: icon8, width: "120px" },
        ],
      };
    },
  };
  </script>
    
    <style scoped lang="less">
  .cooperative {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
    background: url('@/assets/bg.png');
    .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 45px;
      margin-bottom: 10px;
      width: 1245px;
      text-align: center;
    }
    .desc {
      text-align: center;
      font-size: 16px;
      line-height: 50px;
    }
    .list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .item {
        font-size: 20px;
        width: 145px;
        margin: 10px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #e1e1e1;
        border-radius: 12px;
        box-shadow: 2px 1px 4px #999;
        .icon {
          img {
              width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  </style>