<template>
  <div>
    <component :is="component"></component>
  </div>
</template>

<script>
import PcComponent from './pc.vue'
import MobileComponent from './mobile.vue'
export default {
  name: "home-page",
  components: {
    PcComponent,
    MobileComponent
  },
  data() {
    return {
      component: "PcComponent",
    };
  },
  methods: {

    detectDeviceType() {
      const ua = navigator.userAgent;
      const mobile = ['Android', 'iPhone', 'iPad'];
      const isMobile = mobile.some(item => ua.includes(item))
      this.component = isMobile ? 'MobileComponent' : 'PcComponent'
    },
  },
  mounted() {
    this.detectDeviceType()
  },
};
</script>

<style scoped lang="less">
</style>